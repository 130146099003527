import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import { sizes, weights } from "../styles/tokens"
import flex from "../styles/flex"
import rem from "../utils/rem"

const Button = ({ children, ...rest }) => <Link {...rest}>{children}</Link>

Button.propTypes = {
  children: PropTypes.node.isRequired,
}

const buttonStyles = {
  color: "#3E3E3E",
  backgroundColor: "#FFF",
  borderRadius: rem(225),
  fontSize: sizes.lg.value,
  fontWeight: weights.normal.value,
  width: rem(220),
  height: rem(58),
  textDecoration: "none",
  ...flex.horizontal,
  ...flex.centerHorizontal,
}
export default styled(Button)({
  ...buttonStyles,
})

export const LinkAsButton = ({ children, href, style, ...rest }) => (
  <a href={href} style={{ ...buttonStyles, ...style }} {...rest}>
    {children}
  </a>
)
